import './App.css';
import React, { useEffect, useState } from 'react';
import SectionContent from './Components/SectionContent';
import folderImage from './images/folder.png';
import raiseHandImage from './images/raise-hand.png';
import musicNote from './images/music-note.png';
import colors from './Res/colors.js';


function App() {
  const [previousSection, setPreviousSection] = useState('');
  const [activeSection, setActiveSection] = useState('projects');

  useEffect(() => {
    let sections = document.getElementsByClassName('section');

    for (var i = 0; i < sections.length; i++) {
      sections[i].style.backgroundColor = colors.gray;
      sections[i].style.border = `1px solid ${colors.white}`
    }
    }, []);

  useEffect(() => {
    changeSectionStyle();
  }, [activeSection]);

  function changeSectionStyle() {
    let current = document.getElementsByClassName(activeSection);
    let previous;

    if (previousSection !== '') {
      previous = document.getElementsByClassName(previousSection);
      previous[0].style.backgroundColor = colors.gray;
      previous[0].style.color = colors.white;
      previous[0].style.boxShadow = 'none';
      previous[0].style.marginBottom = '0';
      previous[0].style.marginRight = '0';
    }

    current[0].style.backgroundColor = colors.white;
    current[0].style.color = colors.black;
    current[0].style.boxShadow = `2px 2px 1px 1px red`;
    current[0].style.marginBottom = '5px';
    current[0].style.marginRight = '5px';

    setPreviousSection(activeSection);
  }

  return (
    <div className='App'>
      <h1 className='header'>Scott Curry</h1>
      <h1 className='header-foldable'>Scott Curry</h1>
      
      <div className='main-content'>
        {/* switchable sections in the body */}
        <div className='sections'>
          <div className='section projects' onClick={() => setActiveSection('projects')}>
            <img src={folderImage} alt='projects' className='section-icon'/>
            <h5 className='section-title'>Projects</h5>
          </div>
          <div className='section about-me' onClick={() => setActiveSection('about-me')}>
            <img src={raiseHandImage} alt='About' className='section-icon'/>
            <h5 className='section-title'>About</h5>
          </div>
          <div className='section music' onClick={() => setActiveSection('music')}>
            <img src={musicNote} alt='About' className='section-icon'/>
            <h5 className='section-title'>Music</h5>
          </div>
        </div>

        {/* section content */}
        <div className='section-content'>
          <SectionContent activeSection = {activeSection} />
        </div>
      </div>
    </div>
  );
}

export default App;

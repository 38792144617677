const aboutMe = {
    aboutMeHeader: "Scott Curry",
    aboutMeText: "Hello! I'm Scott, a multi-faceted developer with a diverse background that extends beyond programming. With a solid foundation built on several years of React experience, I've honed my skills in crafting seamless and dynamic web experiences.\
    Outside the digital realm, I'm a seasoned musician with a rich history in music, both as a songwriter and a producer. I've navigated the realms of IT, streaming, and even contributed my skills to the realms of music and theater.\
    This unique blend of experiences allows me to approach web development (as well as other ventures!) with a creative and versatile mindset.\
    This website may not be the fanciest on the block, but that's intentional. I believe in substance over style, focusing on delivering efficient, user-friendly, and intuitive interfaces. It's not about the glitz and glamour; it's about functionality and purpose.\
    Beyond coding, I'm always up for diverse collaborations. Whether it's writing insightful reviews or engaging blog posts, or joining forces to create or produce music, I'm open to exploring the intersection of technology and creativity.\
    Let's connect and explore the endless possibilities that lie at the crossroads of code and culture!"
}

export default aboutMe;
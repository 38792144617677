import './SectionContent.css'
import cmiImage from '../images/Count Me In.png'
import wsieImage from '../images/wsie.png'
import wpImage from '../images/wp_icon.png'
import initImage from '../images/innitracker.png'
import aboutMeRes from '../Res/about.js'
import githubImage from '../images/github_img.png'
import pfImage from '../images/phasmofinder_img.png'

export default function SectionContent({activeSection}) {
    function setData(as) {
        let newDivClassname = as + '-div';
        let newDiv;

        if (newDivClassname === 'projects-div') {
            newDiv = 
            <div className={newDivClassname}>
                <table className='projects-table'>
                    <tbody>
                        <tr>
                            <td className='project-link'>
                                <a href='https://phasmofinder.com'>
                                    <img src={pfImage} alt='phasmofinderImage' className='project-image' />
                                    <p>Phasmofinder</p>
                                    <p className='project-description'>AWS hosted Phasmophobia evidence trainer</p>
                                </a>
                            </td>
                        </tr>
                        <tr>
                            <td className='project-link'>
                                <a href='https://play.google.com/store/apps/details?id=com.scappworks.countmein'>
                                    <img src={cmiImage} alt='cmiImage' className='project-image' />
                                    <p>Count Me In</p>
                                    <p className='project-description'>Practice card counting</p>
                                </a>
                            </td>
                        </tr>

                        <tr>
                            <td className='project-link'>
                                <a href='https://play.google.com/store/apps/details?id=com.scappworks.whereshouldieat'>
                                    <img src={wsieImage} alt='wsieImage' className='project-image' />
                                    <p>Where Should I Eat?</p>
                                    <p className='project-description'>Get help picking a restaurant</p>
                                </a>
                            </td>
                        </tr>

                        <tr>
                            <td className='project-link'>
                                <a href='https://play.google.com/store/apps/details?id=com.scappworks.weeklyplanner'>
                                    <img src={wpImage} alt='wpImage' className='project-image' />
                                    <p>Weekly Planner</p>
                                    <p className='project-description'>Plan your week</p>
                                </a>
                            </td>
                        </tr>

                        <tr>
                            <td className='project-link'>
                                <a href='https://play.google.com/store/apps/details?id=first.example.initracker'>
                                    <img src={initImage} alt='initImage' className='project-image' />
                                    <p>Initracker</p>
                                    <p className='project-description'>Track your players initiative</p>
                                </a>
                            </td>
                        </tr>
                        <tr>
                            <td className='project-link'>
                                <a href='https://github.com/scappworks'>
                                    <img src={githubImage} alt='githubImage' className='project-image' />
                                    <p>scappworks</p>
                                    <p className='project-description'>Check out my Github</p>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        }

        else if (newDivClassname ==='about-me-div') {
            newDiv =
            <div className={newDivClassname}>
                <h3 className='about-me-header'>{ aboutMeRes.aboutMeHeader }</h3>
                <p className='about-me-body'>{ aboutMeRes.aboutMeText }</p>
            </div>
        }

        else if (newDivClassname ==='music-div') {
            newDiv =
            <div className={newDivClassname}>
                <iframe
                    src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1748945751&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true">
                </iframe>
            </div>
        }

        return newDiv;
    }

    return (setData(activeSection));
}